import React, { useEffect } from 'react';

import SingleFeature from "../../components/Features/SingleFeature";
import featuresData from "../../components/Features/featuresData";
import Breadcrumb from '../../components/Common/Breadcrumb';


const ServicesPage = () => {

  useEffect(() => {
    const pageTitle = "Our Services | Avadhani Softwares";
    document.title = pageTitle;

    const description = "Explore our comprehensive range of innovative software solutions designed to streamline your business processes and elevate your digital presence.";
    document.querySelector('meta[name="description"]').setAttribute("content", description);
  }, []);

  return (
    <>
      <Breadcrumb
        pageName="Our Services"
        description="Explore our comprehensive range of innovative software solutions designed to streamline your business processes and elevate your digital presence."
      />
      <>
        <section id="features" className="py-16 md:py-20 lg:py-28 bg-black">
          <div className="container">
            <div className="grid grid-cols-1 gap-x-8 gap-y-14 md:grid-cols-2 lg:grid-cols-3">
              {featuresData.map((feature) => (
                <SingleFeature key={feature.id} feature={feature} />
              ))}
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default ServicesPage;
