import React, { useEffect } from 'react';
import AboutSectionOne from '../../components/About/AboutSectionOne';
import AboutSectionTwo from '../../components/About/AboutSectionTwo';
import Breadcrumb from '../../components/Common/Breadcrumb';

const AboutPage = () => {

  useEffect(() => {
    const pageTitle = "About Us | Avadhani Softwares";
    const description = "Welcome to the heart of our journey—a place where ingenuity meets ambition, where we craft tomorrow's solutions today, fueling your aspirations beyond imagination.";
    
    document.title = pageTitle;
    document.querySelector('meta[name="description"]').setAttribute("content", description);
  }, []);

  return (
    <>
      <Breadcrumb
        pageName="About"
        description="Welcome to the heart of our journey—a place where ingenuity meets ambition, where we craft tomorrow's solutions today, fueling your aspirations beyond imagination."
      />
      <AboutSectionOne />
      <AboutSectionTwo />
    </>
  );
};

export default AboutPage;
