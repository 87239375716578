import React, { useEffect } from 'react';
import AboutSectionOne from "../../components/About/AboutSectionOne"
import AboutSectionTwo from "../../components/About/AboutSectionTwo";
import Hero from '../../components/Hero';
import ScrollUp from '../../components/Common/ScrollUp';
import Features from '../../components/Features';
import Contact from '../../components/Contact';

const Home = () => {

    useEffect(() => {
        const pageTitle = "Avadhani Softwares";
        const description = "Welcome to the heart of our journey—a place where ingenuity meets ambition, where we craft tomorrow's solutions today, fueling your aspirations beyond imagination.";
        
        document.title = pageTitle;
        document.querySelector('meta[name="description"]').setAttribute("content", description);
    }, []);

  return (
    <>
        <ScrollUp />
        <Hero />
        <Features />
        <AboutSectionOne />
        <AboutSectionTwo />
        <Contact />
    </>
  );
}

export default Home;