import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
// import ScrollToTop from './components/ScrollToTop';
// import './node_modules/react-modal-video/css/modal-video.css';
import '../styles/index.css';

const inter = { className: 'Inter' }; // Define your Inter font class here

const RootLayout = ({ children }) => {
  return (
    <div className={`bg-[#FCFCFC] dark:bg-black ${inter.className}`}>
      <Header />
      {children}
      <Footer />
      {/* <ScrollToTop /> */}
    </div>
  );
};

export default RootLayout;
