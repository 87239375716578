
import React, { useEffect } from 'react';
import Breadcrumb from "../../components/Common/Breadcrumb";
import Contact from "../../components/Contact";

const ContactPage = () => {
  useEffect(() => {
    const pageTitle = "Contact Us | Avadhani Softwares";
    document.title = pageTitle;

    const description = "Get in touch with us directly - we're here to answer your questions, address your concerns, and help you on your journey.";
    document.querySelector('meta[name="description"]').setAttribute("content", description);
  }, []);

  return (
    <section  id="contact" className="bg-black">
      <Breadcrumb
        pageName="Contact Us"
        description="Get in touch with us directly—we're here to answer your questions, address your concerns, and help you on your journey."
      />
      
        <div style={{ marginLeft: "5%" }} className="bg-black text-white">
          <h2>Support</h2>
          <hr style={{ width: "100px" }} />
          <p>avadhanisoftwares@gmail.com</p>
          <p>+91 - 9902014879</p>
          <p>+91 - 8792566115</p>
        </div>

        <Contact />
    </section>
  );
};

export default ContactPage;

